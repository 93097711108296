import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    isSuperAdmin() {
      return this.currentUser.type === 'SuperAdmin';
    },
  },
};
